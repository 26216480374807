import React, { useEffect, useState, useCallback } from "react";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useGlobalContext } from "../Config";

const Tabla = (props) => {

  const { baseUrl } = useGlobalContext();
  const { headerText } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const { styleProperties } = useGlobalContext();

  const [desde, setDesde] = useState(1);
  const [hasta, setHasta] = useState(12);
  const [num, setNum] = useState(1);
  const [tabla, setTabla] = useState();

  const options = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '10', value: 10},
    {label: '11', value: 11},
    {label: '12', value: 12}
  ];
 
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    switch (name) {
      case "num":
        setNum(val);
        break;
      case "desde":
        setDesde(val);
        break;
      case "hasta":
        setHasta(val);
        break;
    }
  };

  const handleSubmit = useCallback((e) => {

    e.preventDefault();

    var endpoint = baseUrl + "reportes/tabla?num=" + num + "&desde=" + desde + "&hasta=" + hasta;
    setLoading(true);
    (async () => {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());
      setTabla(data.tabla);
      setLoading(false);
    })();    

  });

  const formatNumber = (value) => {
    if (value != undefined) {
      return value.toLocaleString('es-CL');
    }
    return null;
  };

  const formatCurrency = (value) => {
    if (value != undefined) {
      return value.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
    }
    return null;
  };

  const numberTemplate = (row) => {
    return formatNumber(row.pesoNeto);
  };  

  const currencyTemplate = (row) => {
    return formatCurrency(row.monto);
  };  

  return (
    <div className="col-12 md:col-12">
      <h5>Tabla</h5>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-1">
              <label htmlFor={num}>Numero</label> 
              <InputText value={num} onChange={(e) => onInputChange(e, 'num')} />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor={desde}>Desde</label> 
              <Dropdown value={desde} onChange={(e) => onInputChange(e, 'desde')} options={options} optionValue="value" optionLabel="label" placeholder="Desde" className="w-full md:w-14rem"/>
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor={hasta}>Hasta</label> 
              <Dropdown value={hasta} onChange={(e) => onInputChange(e, 'hasta')} options={options} optionValue="value" optionLabel="label" placeholder="Hasta" className="w-full md:w-14rem"/>
            </div>
            <div className="field col-12 md:col-1">
              <Button
                type="submit"
                name="filterButton"
                className="login-button mb-3 px-3"
                label="Aplicar"
                style={{ backgroundColor: styleProperties.button.backgroundColor }}
              />
            </div>
          </div>
        </form>
        <DataTable value={tabla} tableStyle={{ minWidth: '50rem' }} showGridlines emptyMessage="No se encontraron filas">
          <Column field="operacion" header="Operación" style={{ width: '50%', textAlign: 'right' }}/>
          <Column field="resultado" header="Resultado" style={{ width: '50%', textAlign: 'right' }}/>
        </DataTable>
      </div>
    </div>
  );
};

export default Tabla;
