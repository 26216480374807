import React from "react";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

const DocumentoSubidoTemplate = ({
  rowData,
  handleFileUpload,
  bajarArchivo,
  exportToPDF,
  isUploading,
}) => {
  const tieneAdjunto = rowData.adjuntos && rowData.adjuntos.length > 0;
  const tipoDocumento = rowData.documento.toLowerCase();

  const tooltipStyles = {
    backgroundColor: "#007bff", // Primary color background
    color: "#ffffff", // White text
    padding: "0.75rem 1rem", // Padding for better readability
    borderRadius: "0.5rem", // Rounded corners
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)", // Soft shadow for depth
    fontSize: "0.95rem", // Slightly larger font for clarity
    fontWeight: "500", // Medium font weight for readability
    textAlign: "center", // Center the text in the tooltip
    transition: "opacity 0.2s ease, transform 0.2s ease", // Smooth fade-in effect
  };

  if (tipoDocumento === "factura afecta" || tipoDocumento === "factura exenta") {
    if (!tieneAdjunto) {
      return (
        <>
          <FileUpload
            mode="basic"
            chooseOptions={{
              className:
                "p-button-rounded p-button-primary p-button-icon-only p-button-lg custom-file-upload",
              icon: "pi pi-upload",
            }}
            multiple={false}
            customUpload
            uploadHandler={(e) => handleFileUpload(e, rowData)}
            disabled={isUploading}
            auto 
          />
         
        </>
      );
    } else {
      const adjunto = rowData.adjuntos[0];
      return (
        <Button
          icon="pi pi-download"
          className="p-button-rounded p-button-success"
          onClick={() => bajarArchivo(rowData.id_documento, adjunto.nombre)}
          disabled={isUploading}
          id={`download-btn-${rowData.id}`} // Tooltip works directly here
          tooltip="Click para descargar el documento."
          tooltipOptions={{ position: "left", style: tooltipStyles }}
        />
      );
    }
  } else if (tipoDocumento === "nota de cobro") {
    return (
      <>
        <Button
          icon="pi pi-file-pdf"
          className="p-button-rounded p-button-warning"
          onClick={() => exportToPDF(rowData)}
          disabled={isUploading}
          id={`pdf-btn-${rowData.id_documento}`}
          tooltip="Click to generate a PDF for this document."
          tooltipOptions={{ position: "left", style: tooltipStyles }}
        />
        <Tooltip
          target={`#pdf-btn-${rowData.id_documento}`}
          content="Genera un PDF para este documento."
          position="left"
          style={tooltipStyles}
        />
      </>
    );
  }

  return null;
};

export default DocumentoSubidoTemplate;
